class uiBadge extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({mode: 'open'});
    }

    connectedCallback() {
        this.render();
    }

    static get observedAttributes() {
        return ['value', 'scheme', 'state'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'value') {
            this.value = newValue;
        }
        if (name === 'scheme') {
            this.scheme = newValue;
        }
        if (name === 'state') {
            this.state = newValue;
        }
        if (this.value || this.scheme || this.state) {
            this.render();
        }
    }

    render() {
        this.shadowRoot.innerHTML = `
            <style>
                :host {
                    display: flex;
                    box-sizing: border-box;
                    display: inline-flex;
                    padding: var(--badge-padding-top) var(--badge-padding-right) var(--badge-padding-bottom) var(--badge-padding-left);
                    justify-content: center;
                    align-items: flex-start;
                    gap: var(--badge-gap-horizontal);

                    border-radius: var(--badge-corner-radius-top-left) var(--badge-corner-radius-top-right) var(--badge-corner-radius-bottom-right) var(--badge-corner-radius-bottom-left);
                    border-style: solid;
                    border-top-width: var(--badge-stroke-top);
                    border-right-width: var(--badge-stroke-right);
                    border-bottom-width: var(--badge-stroke-bottom);
                    border-left-width: var(--badge-stroke-left);

                    background: var(--${this.scheme}-fill-${this.state == 'disabled' ? 'disabled' : 'enabled'});
                    border-color: var(--${this.scheme}-stroke-${this.state == 'disabled' ? 'disabled' : 'enabled'});
                    outline-color: var(--${this.scheme}-stroke-${this.state == 'disabled' ? 'disabled' : 'enabled'});

                    cursor: default;

                    ${this.value == 0 || !this.value ? 'opacity: 0;' : 'opacity: 1;'}
                }
                ${this.state == 'disabled' ? '' : `
                    :host(:hover) {
                        background: var(--${this.scheme}-fill-hovered);
                        border-color: var(--${this.scheme}-stroke-hovered);
                        outline-color: var(--${this.scheme}-stroke-hovered);
                    }
                    :host(:active) {
                        background: var(--${this.scheme}-fill-pressed);
                        border-color: var(--${this.scheme}-stroke-pressed);
                        outline-color: var(--${this.scheme}-stroke-pressed);
                    }
                `}
                .text {
                    box-sizing: border-box;
                    min-width: 16px;
                    margin: calc(-1 * var(--badge-stroke-top)) calc(-1 * var(--badge-stroke-right)) calc(-1 * var(--badge-stroke-bottom)) calc(-1 * var(--badge-stroke-left));
                    display: flex;
                    padding: var(--badge-text-padding-top) var(--badge-text-padding-right) var(--badge-text-padding-bottom) var(--badge-text-padding-left);
                    justify-content: center;
                    align-items: flex-start;
                    font: var(--caption-small);

                    color: var(--${this.scheme}-text-${this.state == 'disabled' ? 'disabled' : 'enabled'});
                }
                ${this.state == 'disabled' ? '' : `
                    :host(:hover) .text {
                        color: var(--${this.scheme}-text-hovered);
                    }
                    :host(:active) .text {
                        color: var(--${this.scheme}-text-pressed);
                    }
                `}
            </style>
            <span class="text">${this.value ? this.value : ''}</span>
        `;
    }

}
customElements.define('ui-badge', uiBadge);