import '../pages/*.js';
class appPage extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({mode: 'open'});
    }

    connectedCallback() {
        this.render();
    }

    static get observedAttributes() {
        return ['page'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'page') {
            this.page = newValue;
            this.render();
        }
    }

    render() {
        console.log('Render: app-page');
        let page = '';
        switch(this.page) {
            case 'home':
                page = `<app-page-home></app-page-home>`
                break;
        }
        this.shadowRoot.innerHTML = `
            <style>
            </style>
            ${page}
        `;
    }

}
customElements.define('app-page', appPage);