class pageHome extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({mode: 'open'});
    }

    connectedCallback() {
        this.render();
    }

    static get observedAttributes() {
        return ['test'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'test') {
            this.test = newValue;
            this.render();
        }
    }

    render() {
        this.shadowRoot.innerHTML = `
            <style>
                :host {display: flex;
                    width: 100%;
                    height: 100%;
                    align-items: flex-start;
                };
                .main {
                    width: 100%;
                    display: flex;
                    padding: 4px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                    flex: 1 0 0;
                    align-self: stretch;
                };
            </style>
            home
        `;
    }

}
customElements.define('app-page-home', pageHome);