import {getData} from './functions/getData.js';
import {uiIcons} from './components/uiIcon.js';
import './components/*.js';

// дата
/* let date = new Date();
let year = date.getFullYear();
let month = date.getMonth() + 1; // Months are 0-based in JavaScript
let day = date.getDate();
let hours = date.getHours();
let minutes = date.getMinutes();
let seconds = date.getSeconds();
let milliseconds = date.getMilliseconds();

let formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}T${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}.${milliseconds < 10 ? '00' + milliseconds : milliseconds < 100 ? '0' + milliseconds : milliseconds}+00:00`;

console.log(formattedDate); */
let date = new Date();
let userOffset = -date.getTimezoneOffset() / 60; // смещение пользователя в часах
let moscowOffset = 3; // смещение Москвы в часах
let diff = userOffset - moscowOffset; // разница между смещениями

let sign = diff < 0 ? '-' : '+'; // знак смещения
let absoluteDiff = Math.abs(diff); // абсолютное значение разницы
let hours = Math.floor(absoluteDiff); // количество полных часов
let minutes = (absoluteDiff - hours) * 60; // количество минут

// форматирование смещения
let formattedOffset = `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

let iso = date.toISOString();
let formattedDate = iso.replace('Z', formattedOffset);
//console.log(formattedDate);

function routing() {
    let path = window.location.pathname;
    let pathValues = path.split('/');
    let directory = pathValues.slice(1);

    if (directory[0] == '') {
        directory[0] = 'home'; // страница по умолчанию
    };
 
    switch (directory[0]) {
        case "home":
            let testQuery = {
                'container': 'test',
                'storage': 'local',
                'role': 'user',
                'collection': 'settings',
                'query': [
                    //{'column': 'public', 'operator': 'equal', 'value': true},
                    //{'column': '$id', 'operator': 'equal', 'value': 'host'}
                ],
                'order': [
                    {'sorting': 'asc', 'column': 'position'}
                ],
                'limit': 1000,
                'offset': 0
            };
            
            function test(updated) {
                getData(testQuery, test, updated).then(data => {
                    document.getElementById('app').innerHTML = `
                        ${data.map(test => `
                            ${test.$id}
                        `).join('')}
                    `;
                })
                .catch((error) => {
                    console.log(error);
                });
            }
            test(false);
            break;
        case "admin":
            document.getElementById('page').innerHTML = `
                <page-layout class="style-surface fill-high">
                    <aside slot="sidebar">aside</aside>
                    <div slot="page">page</div>
                    <div slot="navbar" class="">
                        <ui-badge scheme="error" state="" value="1"><ui-icon slot="icon" value="burger24" class="style-surface" test="style-surface"></ui-icon></ui-badge>
                        <ui-icon value="burger24" class="style-surface" test="style-surface"></ui-icon>
                    </div>
                </page-layout>
                <div class="style-transparent fill stroke">${uiIcons('burger24')}</div>
            `;
            break;
        default:
            document.getElementById('app').innerHTML = `
                <p>Page not found</p>
            `;
    };
};
//routing();

/* Функция перехода по ссылкам */
function transition(url, transition) {
    let a = ''; 
    let query = url.substr(2);
    if (query != '') {
        a = query.split('&').reduce(function (res, item) {
            let parts = item.split('=');
            res[parts[0]] = parts[1];
            return res;
        }, {});
    };
    if (document.documentElement.clientWidth < '880') {
        switch(transition) {
            case 'slidein':
                document.getElementById("app").style.cssText = "opacity: 0; transform: translateX(16%); min-height: calc(var(--vh, 1vh) * 100);";
                document.getElementById("app").classList.add("transition");
                history.pushState(a.page, null, url);
                routing();
                window.scrollTo(0, 1);
                setTimeout(function() {
                    document.getElementById("app").style.cssText = "opacity: 1; transform: translateX(0%); transition: opacity 200ms ease-in, transform 200ms ease-in-out; min-height: calc(var(--vh, 1vh) * 100);";
                }, 10);
                setTimeout(function() {
                    document.getElementById("app").removeAttribute("style");
                    document.getElementById("app").removeAttribute("class");
                }, 210);
                break;
            case 'slideup':
                document.getElementById("app").style.cssText = "opacity: 0; transform: translateY(8%); min-height: calc(var(--vh, 1vh) * 100);";
                document.getElementById("app").classList.add("transition");
                history.pushState(a.page, null, url);
                routing();
                window.scrollTo(0, 1);
                setTimeout(function() {
                    document.getElementById("app").style.cssText = "opacity: 1; transform: translateY(0%); transition: opacity 200ms ease-in, transform 200ms ease-in-out; min-height: calc(var(--vh, 1vh) * 100);";
                }, 10);
                setTimeout(function() {
                    document.getElementById("app").removeAttribute("style");
                    document.getElementById("app").removeAttribute("class");
                }, 210);
                break;
            case 'slideout':
                document.getElementById("app").style.cssText = "opacity: 0; transform: translateX(16%); transition: opacity 200ms ease-out, transform 200ms ease-in-out;";
                document.getElementById("app").classList.add("transition");
                setTimeout(function() {
                    history.pushState(a.page, null, url);
                    routing();
                    getPageScroll(a.page);
                }, 200);
                setTimeout(function() {
                    document.getElementById("app").style.cssText = "opacity: 0; transform: translateX(-3%);";
                }, 210);
                setTimeout(function() {
                    document.getElementById("app").style.cssText = "opacity: 1; transform: translateX(0%); transition: opacity 100ms ease-in, transform 100ms ease-in-out;";
                }, 240);
                setTimeout(function() {
                    document.getElementById("app").removeAttribute("style");
                    document.getElementById("app").removeAttribute("class");
                }, 340);
                break;
            case 'slidedown':
                document.getElementById("app").style.cssText = "opacity: 0; transform: translateY(16%); transition: opacity 200ms ease-out, transform 200ms ease-in-out;";
                document.getElementById("app").classList.add("transition");
                setTimeout(function() {
                    history.pushState(a.page, null, url);
                    routing();
                    getPageScroll(a.page);
                }, 200);
                setTimeout(function() {
                    document.getElementById("app").style.cssText = "opacity: 0; transform: translateY(-2%);";
                }, 210);
                setTimeout(function() {
                    document.getElementById("app").style.cssText = "opacity: 1; transform: translateY(0%); transition: opacity 100ms ease-in, transform 100ms ease-in-out;";
                }, 240);
                setTimeout(function() {
                    document.getElementById("app").removeAttribute("style");
                    document.getElementById("app").removeAttribute("class");
                }, 340);
                break;
            case 'fade':
                document.getElementById("app").style.cssText = "opacity: 0; transition: opacity 100ms ease-out;";
                setTimeout(function() {
                    document.getElementById("app").style.cssText = "opacity: 1; transition: opacity 100ms ease-in;";
                    history.pushState(a.page, null, url);
                    routing();
                    window.scrollTo(0, 1);
                }, 100);
                setTimeout(function() {
                    document.getElementById("app").removeAttribute("style");
                }, 200);
                break;
            case 'scrolltop':
                history.pushState(a.page, null, url);
                routing();
                window.scrollTo(0, 1);
                break;
            default:
                history.pushState(a.page, null, url);
                routing();
        }
    } else {
        switch(transition) {
            case 'slidein':
            case 'slideup':
            case 'fade':
                document.getElementById("app").style.cssText = "opacity: 0; transition: opacity 100ms ease-out;";
                setTimeout(function() {
                    document.getElementById("app").style.cssText = "opacity: 1; transition: opacity 100ms ease-in;";
                    history.pushState(a.page, null, url);
                    routing();
                    window.scrollTo(0, 1);
                }, 100);
                setTimeout(function() {
                    document.getElementById("app").removeAttribute("style");
                }, 200);
                break;
            case 'scrolltop':
                history.pushState(a.page, null, url);
                routing();
                window.scrollTo(0, 1);
                break;
            case 'slideout':
            case 'slidedown':
                document.getElementById("app").style.cssText = "opacity: 0; transition: opacity 100ms ease-out;";
                setTimeout(function() {
                    document.getElementById("app").style.cssText = "opacity: 1; transition: opacity 100ms ease-in;";
                    history.pushState(a.page, null, url);
                    routing();
                    getPageScroll(a.page);
                }, 100);
                setTimeout(function() {
                    document.getElementById("app").removeAttribute("style");
                }, 200);
                break;
            default:
                history.pushState(a.page, null, url);
                routing();
        };
    };
};

/* Обновляем страницу при изменения url в истории браузера */
/* window.onpopstate = function() {
    routing();
}; */