const _temp0 = require("./components/appLayout.js");
const _temp1 = require("./components/appPage.js");
const _temp2 = require("./components/navBarTab.js");
const _temp3 = require("./components/uiBadge.js");
const _temp4 = require("./components/uiIcon.js");
module.exports = {
  "appLayout": _temp0,
  "appPage": _temp1,
  "navBarTab": _temp2,
  "uiBadge": _temp3,
  "uiIcon": _temp4
}