export function uiIcons(value) {
    let template = '';
    switch(value) {
        case "burger24":
            template = new String(`
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M3.25 14.5C2.83579 14.5 2.5 14.8358 2.5 15.25C2.5 15.6642 2.83579 16 3.25 16H5.68934L4.18934 14.5H3.25Z" class="red-fill"/>
                    <path d="M8.81066 14.5L7.31066 16H20.75C21.1642 16 21.5 15.6642 21.5 15.25C21.5 14.8358 21.1642 14.5 20.75 14.5H8.81066Z" class="red-fill"/>
                    <path d="M1.57292 13.75C1.21664 14.1481 1 14.6737 1 15.25C1 16.4926 2.00736 17.5 3.25 17.5H20.75C21.9926 17.5 23 16.4926 23 15.25C23 14.6737 22.7834 14.1481 22.4271 13.75C22.0151 14.2103 21.4164 14.5 20.75 14.5C21.1642 14.5 21.5 14.8358 21.5 15.25C21.5 15.6642 21.1642 16 20.75 16H7.31066L7.03033 16.2803C6.73744 16.5732 6.26256 16.5732 5.96967 16.2803L5.68934 16H3.25C2.83579 16 2.5 15.6642 2.5 15.25C2.5 14.8358 2.83579 14.5 3.25 14.5C2.58363 14.5 1.98491 14.2103 1.57292 13.75Z" class="red-stroke"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 12.25C1 11.0074 2.00736 10 3.25 10H20.75C21.9926 10 23 11.0074 23 12.25C23 13.4926 21.9926 14.5 20.75 14.5H8.81066L7.03033 16.2803C6.73744 16.5732 6.26256 16.5732 5.96967 16.2803L4.18934 14.5H3.25C2.00736 14.5 1 13.4926 1 12.25ZM3.25 11.5C2.83579 11.5 2.5 11.8358 2.5 12.25C2.5 12.6642 2.83579 13 3.25 13H4.5C4.69891 13 4.88968 13.079 5.03033 13.2197L6.5 14.6893L7.96967 13.2197C8.11032 13.079 8.30109 13 8.5 13H20.75C21.1642 13 21.5 12.6642 21.5 12.25C21.5 11.8358 21.1642 11.5 20.75 11.5H3.25Z" class="yellow-stroke"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 11.5C2.83579 11.5 2.5 11.8358 2.5 12.25C2.5 12.6642 2.83579 13 3.25 13H4.5C4.69891 13 4.88968 13.079 5.03033 13.2197L6.5 14.6893L7.96967 13.2197C8.11032 13.079 8.30109 13 8.5 13H20.75C21.1642 13 21.5 12.6642 21.5 12.25C21.5 11.8358 21.1642 11.5 20.75 11.5H3.25Z" class="yellow-fill"/>
                    <path d="M1.5 8.75C1.5 7.83721 3.41252 3.5 12 3.5C20.5875 3.5 22.5 7.83721 22.5 8.75C22.5 9.44036 21.9404 10 21.25 10H2.75C2.05964 10 1.5 9.44036 1.5 8.75Z" class="orange-fill"/>
                    <path d="M1.5 19.25V17.5H22.5V19.25C22.5 19.9404 21.9404 20.5 21.25 20.5H2.75C2.05964 20.5 1.5 19.9404 1.5 19.25Z" class="orange-fill"/>
                    <path d="M9.53033 4.71967C9.23744 4.42678 8.76256 4.42678 8.46967 4.71967C8.17678 5.01256 8.17678 5.48744 8.46967 5.78033L9.46967 6.78033C9.76256 7.07322 10.2374 7.07322 10.5303 6.78033C10.8232 6.48744 10.8232 6.01256 10.5303 5.71967L9.53033 4.71967Z" class="orange-stroke"/>
                    <path d="M8.03033 6.71967C8.32322 7.01256 8.32322 7.48744 8.03033 7.78033L7.03033 8.78033C6.73744 9.07322 6.26256 9.07322 5.96967 8.78033C5.67678 8.48744 5.67678 8.01256 5.96967 7.71967L6.96967 6.71967C7.26256 6.42678 7.73744 6.42678 8.03033 6.71967Z" class="orange-stroke"/>
                    <path d="M14.5303 4.71967C14.2374 4.42678 13.7626 4.42678 13.4697 4.71967C13.1768 5.01256 13.1768 5.48744 13.4697 5.78033L14.4697 6.78033C14.7626 7.07322 15.2374 7.07322 15.5303 6.78033C15.8232 6.48744 15.8232 6.01256 15.5303 5.71967L14.5303 4.71967Z" class="orange-stroke"/>
                    <path d="M13.0303 7.78033C13.3232 7.48744 13.3232 7.01256 13.0303 6.71967C12.7374 6.42678 12.2626 6.42678 11.9697 6.71967L10.9697 7.71967C10.6768 8.01256 10.6768 8.48744 10.9697 8.78033C11.2626 9.07322 11.7374 9.07322 12.0303 8.78033L13.0303 7.78033Z" class="orange-stroke"/>
                    <path d="M18.0303 7.78033C18.3232 7.48744 18.3232 7.01256 18.0303 6.71967C17.7374 6.42678 17.2626 6.42678 16.9697 6.71967L15.9697 7.71967C15.6768 8.01256 15.6768 8.48744 15.9697 8.78033C16.2626 9.07322 16.7374 9.07322 17.0303 8.78033L18.0303 7.78033Z" class="orange-stroke"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.75C0 6.66279 2.97879 2 12 2C21.0212 2 24 6.66279 24 8.75C24 10.2688 22.7688 11.5 21.25 11.5H2.75C1.23122 11.5 0 10.2688 0 8.75ZM12 3.5C3.41252 3.5 1.5 7.83721 1.5 8.75C1.5 9.44036 2.05964 10 2.75 10H21.25C21.9404 10 22.5 9.44036 22.5 8.75C22.5 7.83721 20.5875 3.5 12 3.5Z" class="orange-stroke"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 16C0.335786 16 0 16.3358 0 16.75V19.25C0 20.7688 1.23122 22 2.75 22H21.25C22.7688 22 24 20.7688 24 19.25V16.75C24 16.3358 23.6642 16 23.25 16H0.75ZM1.5 19.25V17.5H22.5V19.25C22.5 19.9404 21.9404 20.5 21.25 20.5H2.75C2.05964 20.5 1.5 19.9404 1.5 19.25Z" class="orange-stroke"/>
                </svg>
            `);
            break;
    }
    return template;
}
class uiIcon extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({mode: 'open'});
    }

    connectedCallback() {
        this.render();
    }

    static get observedAttributes() {
        return ['value', 'test'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'value') {
            this.value = newValue;
        }
        if (name === 'test') {
            this.test = newValue;
        }
        if (this.value || this.test) {
            this.render();
        }
    }

    render() {
        switch(this.value) {
            case "burger24":
                this.shadowRoot.innerHTML = `
                    <!--link rel="stylesheet" href="/index.2212206d.css" /-->
                    <svg class="${this.test}" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M3.25 14.5C2.83579 14.5 2.5 14.8358 2.5 15.25C2.5 15.6642 2.83579 16 3.25 16H5.68934L4.18934 14.5H3.25Z" class="red-fill"/>
                        <path d="M8.81066 14.5L7.31066 16H20.75C21.1642 16 21.5 15.6642 21.5 15.25C21.5 14.8358 21.1642 14.5 20.75 14.5H8.81066Z" class="red-fill"/>
                        <path d="M1.57292 13.75C1.21664 14.1481 1 14.6737 1 15.25C1 16.4926 2.00736 17.5 3.25 17.5H20.75C21.9926 17.5 23 16.4926 23 15.25C23 14.6737 22.7834 14.1481 22.4271 13.75C22.0151 14.2103 21.4164 14.5 20.75 14.5C21.1642 14.5 21.5 14.8358 21.5 15.25C21.5 15.6642 21.1642 16 20.75 16H7.31066L7.03033 16.2803C6.73744 16.5732 6.26256 16.5732 5.96967 16.2803L5.68934 16H3.25C2.83579 16 2.5 15.6642 2.5 15.25C2.5 14.8358 2.83579 14.5 3.25 14.5C2.58363 14.5 1.98491 14.2103 1.57292 13.75Z" class="red-stroke"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 12.25C1 11.0074 2.00736 10 3.25 10H20.75C21.9926 10 23 11.0074 23 12.25C23 13.4926 21.9926 14.5 20.75 14.5H8.81066L7.03033 16.2803C6.73744 16.5732 6.26256 16.5732 5.96967 16.2803L4.18934 14.5H3.25C2.00736 14.5 1 13.4926 1 12.25ZM3.25 11.5C2.83579 11.5 2.5 11.8358 2.5 12.25C2.5 12.6642 2.83579 13 3.25 13H4.5C4.69891 13 4.88968 13.079 5.03033 13.2197L6.5 14.6893L7.96967 13.2197C8.11032 13.079 8.30109 13 8.5 13H20.75C21.1642 13 21.5 12.6642 21.5 12.25C21.5 11.8358 21.1642 11.5 20.75 11.5H3.25Z" class="yellow-stroke"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 11.5C2.83579 11.5 2.5 11.8358 2.5 12.25C2.5 12.6642 2.83579 13 3.25 13H4.5C4.69891 13 4.88968 13.079 5.03033 13.2197L6.5 14.6893L7.96967 13.2197C8.11032 13.079 8.30109 13 8.5 13H20.75C21.1642 13 21.5 12.6642 21.5 12.25C21.5 11.8358 21.1642 11.5 20.75 11.5H3.25Z" class="yellow-fill"/>
                        <path d="M1.5 8.75C1.5 7.83721 3.41252 3.5 12 3.5C20.5875 3.5 22.5 7.83721 22.5 8.75C22.5 9.44036 21.9404 10 21.25 10H2.75C2.05964 10 1.5 9.44036 1.5 8.75Z" class="orange-fill"/>
                        <path d="M1.5 19.25V17.5H22.5V19.25C22.5 19.9404 21.9404 20.5 21.25 20.5H2.75C2.05964 20.5 1.5 19.9404 1.5 19.25Z" class="orange-fill"/>
                        <path d="M9.53033 4.71967C9.23744 4.42678 8.76256 4.42678 8.46967 4.71967C8.17678 5.01256 8.17678 5.48744 8.46967 5.78033L9.46967 6.78033C9.76256 7.07322 10.2374 7.07322 10.5303 6.78033C10.8232 6.48744 10.8232 6.01256 10.5303 5.71967L9.53033 4.71967Z" class="orange-stroke"/>
                        <path d="M8.03033 6.71967C8.32322 7.01256 8.32322 7.48744 8.03033 7.78033L7.03033 8.78033C6.73744 9.07322 6.26256 9.07322 5.96967 8.78033C5.67678 8.48744 5.67678 8.01256 5.96967 7.71967L6.96967 6.71967C7.26256 6.42678 7.73744 6.42678 8.03033 6.71967Z" class="orange-stroke"/>
                        <path d="M14.5303 4.71967C14.2374 4.42678 13.7626 4.42678 13.4697 4.71967C13.1768 5.01256 13.1768 5.48744 13.4697 5.78033L14.4697 6.78033C14.7626 7.07322 15.2374 7.07322 15.5303 6.78033C15.8232 6.48744 15.8232 6.01256 15.5303 5.71967L14.5303 4.71967Z" class="orange-stroke"/>
                        <path d="M13.0303 7.78033C13.3232 7.48744 13.3232 7.01256 13.0303 6.71967C12.7374 6.42678 12.2626 6.42678 11.9697 6.71967L10.9697 7.71967C10.6768 8.01256 10.6768 8.48744 10.9697 8.78033C11.2626 9.07322 11.7374 9.07322 12.0303 8.78033L13.0303 7.78033Z" class="orange-stroke"/>
                        <path d="M18.0303 7.78033C18.3232 7.48744 18.3232 7.01256 18.0303 6.71967C17.7374 6.42678 17.2626 6.42678 16.9697 6.71967L15.9697 7.71967C15.6768 8.01256 15.6768 8.48744 15.9697 8.78033C16.2626 9.07322 16.7374 9.07322 17.0303 8.78033L18.0303 7.78033Z" class="orange-stroke"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.75C0 6.66279 2.97879 2 12 2C21.0212 2 24 6.66279 24 8.75C24 10.2688 22.7688 11.5 21.25 11.5H2.75C1.23122 11.5 0 10.2688 0 8.75ZM12 3.5C3.41252 3.5 1.5 7.83721 1.5 8.75C1.5 9.44036 2.05964 10 2.75 10H21.25C21.9404 10 22.5 9.44036 22.5 8.75C22.5 7.83721 20.5875 3.5 12 3.5Z" class="orange-stroke"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 16C0.335786 16 0 16.3358 0 16.75V19.25C0 20.7688 1.23122 22 2.75 22H21.25C22.7688 22 24 20.7688 24 19.25V16.75C24 16.3358 23.6642 16 23.25 16H0.75ZM1.5 19.25V17.5H22.5V19.25C22.5 19.9404 21.9404 20.5 21.25 20.5H2.75C2.05964 20.5 1.5 19.9404 1.5 19.25Z" class="orange-stroke"/>
                    </svg>
                `;
                break;
        }
    }

}
customElements.define('ui-icon', uiIcon);