import { Client, Databases, Query } from "appwrite";

const endpoint = 'https://apiproto.ru/v1';
const project = '65e0ba9e8cc7e97b05cb';
const database = '65e0bf7048b8e4d3bad6';
export async function getData(input, rerender, rerendered) {
    let storage = localStorage;
    if (input.storage == 'session') {
        storage = sessionStorage;
    };

    let storageData = [];
    for (let i = 0; i < storage.length; i++) {
        let key = storage.key(i);
        if (key.startsWith(`collection_${input.role == 'admin' ? 'adm_' : ''}${input.collection}_id_`)) {
            let item = JSON.parse(storage.getItem(key));
            if (item) {
                storageData.push(item);
            };
        };
    };
    let filteredData = storageData;
    input.query.forEach((row) => {
        switch(row.operator) {
            case 'equal': // Равно
                if (typeof row.value === 'boolean') {
                    /* Сравниваем булевые значения */
                    filteredData = storageData.filter(item => item[row.column] == row.value);
                } else if (isNaN(row.value)) {
                    const dateValue = new Date(row.value);
                    if (dateValue.toString() === "Invalid Date") {
                        /* Сравниваем строки */
                        filteredData = storageData.filter(item => String(item[row.column]) == row.value);
                    } else {
                        /* Сравниваем даты */
                        filteredData = storageData.filter(item => new Date(item[row.column]).valueOf() == dateValue.valueOf());
                    }
                } else {
                    /* Сравниваем числа */
                    filteredData = storageData.filter(item => parseFloat(item[row.column]) == parseFloat(row.value));
                }
                break;
            case 'notEqual': // Не равно
                if (typeof row.value === 'boolean') {
                    /* Сравниваем булевые значения */
                    filteredData = storageData.filter(item => item[row.column] != row.value);
                } else if (isNaN(row.value)) {
                    const dateValue = new Date(row.value);
                    if (dateValue.toString() === "Invalid Date") {
                        /* Сравниваем строки */
                        filteredData = storageData.filter(item => String(item[row.column]) != row.value);
                    } else {
                        /* Сравниваем даты */
                        filteredData = storageData.filter(item => new Date(item[row.column]).valueOf() != dateValue.valueOf());
                    }
                } else {
                    /* Сравниваем числа */
                    filteredData = storageData.filter(item => parseFloat(item[row.column]) != parseFloat(row.value));
                }
                break;
            case 'less': // Меньше
                if (isNaN(row.value)) {
                    const dateValue = new Date(row.value);
                    if (dateValue.toString() === "Invalid Date") {
                        /* Сравниваем строки */
                        filteredData = storageData.filter(item => String(item[row.column]) < row.value);
                    } else {
                        /* Сравниваем даты */
                        filteredData = storageData.filter(item => new Date(item[row.column]).valueOf() < dateValue.valueOf());
                    }
                } else {
                    /* Сравниваем числа */
                    filteredData = storageData.filter(item => parseFloat(item[row.column]) < parseFloat(row.value));
                }
                break;
            case 'lessEqual': // Меньше или равен
                if (isNaN(row.value)) {
                    const dateValue = new Date(row.value);
                    if (dateValue.toString() === "Invalid Date") {
                        /* Сравниваем строки */
                        filteredData = storageData.filter(item => String(item[row.column]) <= row.value);
                    } else {
                        /* Сравниваем даты */
                        filteredData = storageData.filter(item => new Date(item[row.column]).valueOf() <= dateValue.valueOf());
                    }
                } else {
                    /* Сравниваем числа */
                    filteredData = storageData.filter(item => parseFloat(item[row.column]) <= parseFloat(row.value));
                }
                break;
            case 'greater': // Больше
                if (isNaN(row.value)) {
                    const dateValue = new Date(row.value);
                    if (dateValue.toString() === "Invalid Date") {
                        /* Сравниваем строки */
                        filteredData = storageData.filter(item => String(item[row.column]) > row.value);
                    } else {
                        /* Сравниваем даты */
                        filteredData = storageData.filter(item => new Date(item[row.column]).valueOf() > dateValue.valueOf());
                    }
                } else {
                    /* Сравниваем числа */
                    filteredData = storageData.filter(item => parseFloat(item[row.column]) > parseFloat(row.value));
                }
                break;
            case 'greaterEqual': // Больше или равен
                if (isNaN(row.value)) {
                    const dateValue = new Date(row.value);
                    if (dateValue.toString() === "Invalid Date") {
                        /* Сравниваем строки */
                        filteredData = storageData.filter(item => String(item[row.column]) >= row.value);
                    } else {
                        /* Сравниваем даты */
                        filteredData = storageData.filter(item => new Date(item[row.column]).valueOf() >= dateValue.valueOf());
                    }
                } else {
                    /* Сравниваем числа */
                    filteredData = storageData.filter(item => parseFloat(item[row.column]) >= parseFloat(row.value));
                }
                break;
            // Редкие кейсы
            case 'between': // Между значениями
                if (isNaN(row.value)) {
                    const dateFrom = new Date(row.from);
                    const dateTo = new Date(row.to);
                    if (dateFrom.toString() === "Invalid Date" || dateTo.toString() === "Invalid Date") {
                        /* Сравниваем строки */
                        filteredData = storageData.filter(item => String(item[row.column]) >= row.from && String(item[row.column]) <= row.to);
                    } else {
                        /* Сравниваем даты */
                        filteredData = storageData.filter(item => {
                            const itemDateValue = new Date(item[row.column]).valueOf();
                            return itemDateValue >= dateFrom.valueOf() && itemDateValue <= dateTo.valueOf();
                        });
                    }
                } else {
                    /* Сравниваем числа */
                    filteredData = storageData.filter(item => {
                        const itemNumericValue = parseFloat(item[row.column]);
                        return itemNumericValue >= parseFloat(row.from) && itemNumericValue <= parseFloat(row.to);
                    });
                }
                break;
            case 'null': // Значение null
                if (row.value === null) {
                    filteredData = storageData.filter(item => item[row.column] === null);
                }
                break;
            case 'notNull': // Значение не null
                if (row.value !== null) {
                    filteredData = storageData.filter(item => item[row.column] !== null);
                }
                break;
            case 'search': // Поиск
                filteredData = storageData.filter(item => {
                    if (Array.isArray(row.column)) {
                        return row.column.some(
                            curr => item[curr] && item[curr].toLowerCase().includes(row.value.toLowerCase())
                        );
                    }
                    else {
                        return item[row.column] && item[row.column].toLowerCase().includes(row.value.toLowerCase());
                    };
                });
                break;
        }
    });
    input.order.forEach((row) => {
        filteredData.sort((a, b) => {
            if (isNaN(a[row.column]) || isNaN(b[row.column])) {
                const dateA = new Date(a[row.column]);
                const dateB = new Date(b[row.column]);
                if (dateA.toString() !== "Invalid Date" && dateB.toString() !== "Invalid Date") {
                    /* Сравниваем даты */
                    return row.sorting === 'desc' ? dateB - dateA : dateA - dateB;
                }
                /* Сравниваем строки */
                return row.sorting === 'desc' ? b[row.column].localeCompare(a[row.column]) : a[row.column].localeCompare(b[row.column]);
            } else {
                /* Сравниваем числа */
                return row.sorting === 'desc' ? b[row.column] - a[row.column] : a[row.column] - b[row.column];
            };
        });
    });
    if (input.limit) {
        if (input.offset == null) {
            input.offset = 0;
        }
        filteredData = filteredData.slice(input.offset, input.offset + input.limit);
    }

    if (input.role == 'user') {
        filteredData = filteredData.filter(item => item.public == true);
    };
    
    if (navigator.onLine && rerendered == false) {
        const client = new Client()
            .setEndpoint(endpoint)
            .setProject(project)
        ;
        const databases = new Databases(client);

        if (filteredData.length < 1 || input.offset == filteredData.length && input.limit > 0) {
            document.getElementById("spinner").style.cssText = "display: block;";
        }

        let query = [];

        input.query.forEach((row) => {
            switch(row.operator) {
                case 'equal': // Равно
                    query.push(Query.equal(row.column, [row.value]));
                    break;
                case 'notEqual': // Не равно
                    query.push(Query.notEqual(row.column, [row.value]));
                    break;
                case 'less': // Меньше
                    query.push(Query.lessThan(row.column, [row.value]));
                    break;
                case 'lessEqual': // Меньше или равен
                    query.push(Query.lessThanEqual(row.column, [row.value]));
                    break;
                case 'greater': // Больше
                    query.push(Query.greaterThan(row.column, [row.value]));
                    break;
                case 'greaterEqual': // Больше или равен
                    query.push(Query.greaterThanEqual(row.column, [row.value]));
                    break;
                // Редкие кейсы
                case 'between': // Между значениями
                    query.push(Query.between(row.column, row.from, row.to));
                    break;
                case 'null': // Значение null
                    query.push(Query.isNull(row.column));
                    break;
                case 'notNull': // Значение не null
                    query.push(Query.isNotNull(row.column));
                    break;
                case 'search': // Поиск
                    query.push(Query.search(row.column, row.value));
                    break;
            }
        });
        input.order.forEach((row) => {
            switch(row.sorting) {
                case 'asc': // По возрастанию
                    query.push(Query.orderAsc(row.column));
                    break;
                case 'desc': // По уменьшению
                    query.push(Query.orderDesc(row.column));
                    break;
            }
        });
        if (input.limit) {
            query.push(Query.limit(input.limit));
        }
        if (input.offset) {
            // Если не число
            if (isNaN(input.offset)) {
                query.push(Query.cursorAfter(input.offset)); // как offset только по id последнего документа
            } else {
                query.push(Query.offset(input.offset));
            }
        }
        if (input.role == 'user') {
            query.push(Query.equal('public', [true]));
        };

        const promise = databases.listDocuments(
            database, 
            input.collection,
            query
        );
        promise.then(function (response) {
            document.getElementById("spinner").style.cssText = "display: none;";
            
            let updated = false;
            if (response.documents) {

/*                 filteredData.forEach((item) => {
                    if (!response.documents.some((doc) => doc.$id === item.$id)) {
                        storage.removeItem(`collection_${input.collection}_id_${item.$id}`);
                        updated = true;
                    }
                });
                response.documents.forEach((doc) => {
                    if (!filteredData.some((item) => item.$id === doc.$id)) {
                        storage.setItem(`collection_${input.collection}_id_${doc.$id}`, JSON.stringify(doc));
                        updated = true;
                    }
                });
                response.documents.forEach((doc) => {
                    const filteredItem = filteredData.find((item) => item.$id === doc.$id);
                    if (filteredItem && filteredItem.$updatedAt !== doc.$updatedAt) {
                        storage.setItem(`collection_${input.collection}_id_${doc.$id}`, JSON.stringify(doc));
                        updated = true;
                    }
                }); */

                const filteredDataMap = new Map(filteredData.map(item => [item.$id, item]));
                const responseDocumentsMap = new Map(response.documents.map(doc => [doc.$id, doc]));
                filteredData.forEach((item) => {
                    if (!responseDocumentsMap.has(item.$id)) {
                        storage.removeItem(`collection_${input.role == 'admin' ? 'adm_' : ''}${input.collection}_id_${item.$id}`);
                        updated = true;
                    }
                });
                response.documents.forEach((doc) => {
                    const filteredItem = filteredDataMap.get(doc.$id);
                    if (!filteredItem || filteredItem.$updatedAt !== doc.$updatedAt) {
                        storage.setItem(`collection_${input.role == 'admin' ? 'adm_' : ''}${input.collection}_id_${doc.$id}`, JSON.stringify(doc));
                        updated = true;
                    }
                });
                if (rerender && updated == true) {
                    rerender(true);
                };
            }
        }, function (error) {
            document.getElementById("spinner").style.cssText = "display: none;";
            console.log(error); // Failure
        });
    } else {
        console.log("Браузер находится в оффлайн режиме");
    };
    return filteredData;
}