/* customElements.define('layout-with-navbar', class extends HTMLElement {

    constructor() {
        super();
        // элемент создан
    }

    connectedCallback() {
        // браузер вызывает этот метод при добавлении элемента в документ
        // (может вызываться много раз, если элемент многократно добавляется/удаляется)
    }

    disconnectedCallback() {
        // браузер вызывает этот метод при удалении элемента из документа
        // (может вызываться много раз, если элемент многократно добавляется/удаляется)
    }

    static get observedAttributes() {
        return [массив имён атрибутов для отслеживания их изменений];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        // вызывается при изменении одного из перечисленных выше атрибутов
    }

    adoptedCallback() {
        // вызывается, когда элемент перемещается в новый документ
        // (происходит в document.adoptNode, используется очень редко)
    }

    // у элемента могут быть ещё другие методы и свойства
  
}); */
class appLayout extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({mode: 'open'});
    }

    connectedCallback() {
        this.render();
    }

    render() {
        let path = window.location.pathname;
        let pathValues = path.split('/');
        let directory = pathValues.slice(1);
    
        if (directory[0] == '') {
            directory[0] = 'home'; // страница по умолчанию
        };
        window.onpopstate = function() {
            document.querySelector('app-page').setAttribute('page', directory[0]);
        };
        console.log('Render: app-layout');

        this.shadowRoot.innerHTML = `
            <style>
                :host {display: flex;
                    width: 100%;
                    height: 100%;
                    align-items: flex-start;
                };
                .main {
                    width: 100%;
                    display: flex;
                    padding: 4px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                    flex: 1 0 0;
                    align-self: stretch;
                };
            </style>
            <aside id="sidebar">sidebar</aside>
            <div class="main">
                <div id="appbar">appbar</div>
                <app-page page="${directory[0]}"></app-page>
                <div id="navbar">navbar</div>
            </div>
        `;
    }

}
customElements.define('app-layout', appLayout);